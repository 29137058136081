<template>
  <modal name="modal-signup" :width="800">
    <div class="modal-header">
      <logo width="auto" height="35px" />
      <i class="icon-zamknij cl-main weight-900 h3 icon-position" @click="closeSignUp" />
    </div>
    <div class="tabs py10 bg-cl-main-smoke h6 secondary-font">
      <span class="animated-dot-signup cl-light-gray" :class="{'cl-main active-dot': activeElem === 'login'}" @click="loginModal()">{{ $t('Log in') }}</span>
      <span class="animated-dot-signup cl-light-gray" :class="{'cl-main active-dot': activeElem === 'register'}" @click="registerModal()">{{ $t('Register') }}</span>
    </div>
    <login v-if="activeElem === 'login'" />
    <register v-if="activeElem === 'register'" />
    <forgot-pass v-if="activeElem === 'forgot-pass'" />
  </modal>
</template>

<script>
import { mapState } from 'vuex'

import Modal from 'theme/components/core/Modal'
import Logo from 'theme/components/core/Logo'

const Login = () => import(/* webpackChunkName: "vsf-login" */ 'theme/components/core/blocks/Auth/Login')
const Register = () => import(/* webpackChunkName: "vsf-register" */ 'theme/components/core/blocks/Auth/Register')
const ForgotPass = () => import(/* webpackChunkName: "vsf-forgotpass" */ 'theme/components/core/blocks/Auth/ForgotPass')

export default {
  name: 'SignUp',
  computed: {
    ...mapState({
      activeElem: state => state.ui.authElem
    })
  },
  methods: {
    closeSignUp () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    loginModal () {
      this.$store.commit('ui/setAuthElem', 'login')
    },
    registerModal () {
      this.$store.commit('ui/setAuthElem', 'register')
    }
  },
  components: {
    Logo,
    Modal,
    Login,
    Register,
    ForgotPass
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$white: color(white);

.icon-position {
  position: absolute;
  top: 10px;
  right: 10px;
}
.animated-dot-signup {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 10px;
    bottom: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #1396EC;
    transition: 0.3s;
  }
}
.active-dot:after {
  opacity: 1;
  bottom: -90%;
}
.modal-header{
  display: none;
  @media (max-width: 767px) {
    box-shadow: 0px 1px 4px 0px #00000029;
    -webkit-box-shadow: 0px 1px 4px 0px #00000029;
    display: flex;
    align-items: center;
    padding: 50px 65px 50px 65px;
    background: $white;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
}
.tabs {
  display: none;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
