import { isServer } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      productPageRoutes: [
        'product',
        'virtual-product',
        'bundle-product',
        'simple-product',
        'downloadable-product',
        'grouped-product',
        'configurable-product'
      ],
      isProductPage: false,
      isCheckoutPage: false,
      isCategoryPage: false,
      isHomePage: false,
      isMyAccount: false
    }
  },
  watch: {
    '$route.name': function () {
      this.setCurrentPage()
    }
  },
  computed: {
    canGoBack () {
      return !this.isHistoryEmpty() && this.isProductPage
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      this.isProductPage = !!this.$route.params.parentSku
      this.isCheckoutPage = this.$route.name === 'checkout'
      this.isCategoryPage = this.$route.name && this.$route.name.includes('urldispatcher') && !this.isProductPage
      this.isHomePage = this.$route.name === 'home'
      this.isMyAccount = this.$route.path.includes('/my-account')
    },
    currentPage () {
      if (this.isProductPage) {
        return 'product'
      } else if (this.isCheckoutPage) {
        return 'checkout'
      } else if (this.isCategoryPage) {
        return 'category'
      } else if (this.isHomePage) {
        return 'homepage'
      } else if (this.isMyAccount) {
        return 'my-account'
      } else {
        return 'unknown'
      }
    },
    // Check if history is empty
    isHistoryEmpty () {
      if (!isServer) {
        return window.history.length <= 1
      }

      return false
    }
  }
}
