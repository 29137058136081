<template>
  <div class="header" :class="(!isCheckoutPage && !isThankYouPage) ? 'basic-header' : 'checkout-header'">
    <!-- Add Organization rich snippet -->
    <div class="hidden" itemscope itemtype="http://schema.org/Organization">
      <link itemprop="url" href="https://kultowy.pl/">
      <div itemprop="contactPoint" itemscope itemtype="http://schema.org/ContactPoint">
        <span itemprop="telephone">+48 111 222 333</span>
        <span itemprop="email">sklep@kultowy.pl</span>
        <span itemprop="contactType">customer service</span>
      </div>
    </div>
    <header
      class="fixed w-100 bg-cl-primary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="hidden-md top-bar container-fluid" v-if="!isCheckoutPage && !isThankYouPage">
        <banner-carousel />
        <account-icon class="p5 pointer top-bar__icon hidden-md" />
      </div>
      <div class="container header-container">
        <div class="row between-xl middle-xs" v-if="!isCheckoutPage || isThankYouPage">
          <div class="col-md-2 pt5 hidden-md logo-block">
            <logo width="155" class="ml40" height="35" />
          </div>
          <desktop-navigation class="col-md-10 col-lg-9 col-xl-9 col-special hidden-md" />
          <div v-show="!filter" class="navigation-mobile col-xs-12 flex-nowrap middle-xs between-xs env-padding-bottom visible-md pwa-styles">
            <home-icon class="p10 visible-md pointer flex-basis-100" />
            <hamburger-icon class="p10 visible-md pointer flex-basis-100" />
            <microcart-icon class="p10 visible-md pointer flex-basis-100" />
            <wishlist-icon class="p10 visible-md pointer flex-basis-100" />
            <account-icon class="p10 visible-md pointer no-outline flex-basis-100 center-xs" />
          </div>
          <div class="end-xs col-md-1 hidden-md">
            <div class="inline-flex right-icons">
              <div v-if="!panelWasHover" @mouseenter="hoverPanel()" class="pointer p10 relative">
                <div class="search-placeholder">
                  <div class="search-panel-placeholder">
                    <label for="search" class="flex middle-xs">
                      <i class="icon-wyszukiwarka search-icon h4 weight-900" />
                    </label>
                    <span class="label">{{ $t('Type what you are looking for...') }}</span>
                  </div>
                </div>
              </div>
              <search-icon v-if="panelWasHover" class="p10 pointer" />
              <wishlist-icon class="p10 icon pointer" />
              <microcart-icon class="p10 icon pointer" />
            </div>
          </div>
        </div>
        <!-- Header for Checkout page -->
        <div
          class="checkout-page row between-xs middle-xs py5"
          :class="$device.isMobile ? 'px0' : 'px15'"
          v-if="isCheckoutPage && !isThankYouPage"
        >
          <div class="col-xs-4 col-md-4 visible-md">
            <router-link :to="localizedRoute('/')">
              <span class="arrow" />
            </router-link>
          </div>
          <div class="col-xs-4 middle-xs col-md-4">
            <logo class="middle-xs" width="130px" :height="$device.isMobile? '24px' : '41px'" />
          </div>
          <div class="col-xs-4 col-md-4 end-xs">
            <div v-if="!$device.isMobile">
              <a
                v-if="!currentUser"
                href="#"
                @click.prevent="gotoAccount"
                class="cl-tertiary links"
              >{{ $t('Login to your account') }}</a>
              <span v-else>{{ $t('You are logged in as {firstname}', currentUser) }}</span>
            </div>
            <div v-else>
              <img src="/assets/payments-logo/seal.png" alt="certum" height="20px">
            </div>
          </div>
        </div>
        <!-- Header for Checkout page END -->
      </div>
    </header>
    <div class="header-placeholder" />
    <!-- <newsletter-popup v-if="loadNewsletterPopupHeader" /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import Logo from 'theme/components/core/Logo'

const AccountIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/AccountIcon')
const MicrocartIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/MicrocartIcon')
const SearchIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/SearchIcon')
const WishlistIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/WishlistIcon')
const DesktopNavigation = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/DesktopNavigation')
const HamburgerIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/HamburgerIcon')
const HomeIcon = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/HomeIcon')
const BannerCarousel = () => import(/* webpackChunkName: "vsf-account-icon" */ 'theme/components/core/blocks/Header/BanerCarousel')

// const NewsletterPopup = () => import(/* webpackChunkName: "vsf-newsletter-modal" */ 'theme/components/core/NewsletterPopup.vue')
// const SearchPanelDesktop = () => import(/* webpackChunkName: "vsf-search-panel" */ 'theme/components/core/blocks/SearchPanel/SearchPanelDesktop.vue')

export default {
  name: 'Header',
  components: {
    AccountIcon,
    Logo,
    MicrocartIcon,
    HamburgerIcon,
    HomeIcon,
    SearchIcon,
    WishlistIcon,
    DesktopNavigation,
    // NewsletterPopup,
    BannerCarousel
  },
  mixins: [CurrentPage],
  data () {
    return {
      panelWasHover: false,
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 71
      // loadNewsletterPopupHeader: false
    }
  },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      filter: state => state.ui.mobileFilter,
      currentUser: state => state.user.current,
      isOpen: state => state.ui.newsletterPopup
    }),
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false
    }
  },
  beforeMount () {
    window.addEventListener(
      'scroll',
      () => {
        this.isScrolling = true
      },
      { passive: true }
    )

    setInterval(() => {
      if (this.isScrolling) {
        this.$bus.$emit('has-scrolled')
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  methods: {
    hoverPanel () {
      this.panelWasHover = true
    },
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false
      } else {
        this.navVisible = true
      }
      this.lastScrollTop = this.scrollTop
    }
    // showNewsletterPopup () {
    //   this.loadNewsletterPopupHeader = true
    //   this.$bus.$emit('modal-show', 'modal-newsletter')
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/base/global_vars';
$z-index: map-get($z-index, modal) + 1;
$color-icon-hover: color(secondary, $colors-background);
$main: color(main);
$white: color(white);

.search-placeholder {
  transition: 0.3s all ease;
  top: 5px;
  right: 5px;
  position: absolute;
  width: 200px;
  @media screen and (max-width: 1350px) {
    width: 35px;
  }
}
.search-panel-placeholder {
  z-index: 2;
  display: flex;
  background-color: #F7F7F7;
  border-radius: 15px;
  .search-icon {
    width: 35px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1396EC;
    opacity: 0.6;
  }
  .label {
    color: gray;
    background: none;
    height: 29px;
    line-height: 34px;
    outline: none;
    padding: 1px 2px;
    font: 400 13.3333px Arial;
    align-items: center;
    display: flex;
    @media screen and (max-width: 1350px) {
      display: none;
    }
  }
}
.pwa-styles {
  @media (display-mode: standalone) {
    padding-bottom: 15px;
  }
}
header {
  top: -24px;
  z-index: 3;
  transition: top 0.2s ease-in-out;
  border-bottom: 1px solid #D6D8DE;
  &.is-visible {
    @media (min-width: 1025px) {
      top: 0;
    }
    @media (max-width: 1024px) {
      top: unset;
    }
  }
  @media (max-width: 1024px) {
    z-index: $z-index;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 0;
    top: unset;
    bottom: 0;
    background: $white;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
}
.navigation-mobile {
  @media (max-width: 1024px) {
    display: flex;
  }
}
.flex-nowrap {
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
}
.basic-header {
  height: 107px;
  @media (max-width: 1024px) {
    height: 0;
  }
}
.checkout-header {
  height: 56px;
  @media (max-width: 1024px) {
    height: 46px;
    header {
      top: 0;
      bottom: unset;
      box-shadow: 0 1px 4px #00000029;
      -webkit-box-shadow: 0 1px 4px #00000029;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      .container {
        padding: 0;
      }
    }
    .checkout-page {
      height: 50px;
    }
    .arrow {
      color: $main;
      width: 22px;
      height: 22px;
      border: 1px solid $main;
      border-radius: 50%;
      display: inline-block;
      margin: 5px;
      position: relative;
      &::after {
        content: '';
        position: relative;
        top: 50%;
        left: 57%;
        height: 8px;
        width: 8px;
        display: block;
        border-left: 1px solid $main;
        border-bottom: 1px solid $main;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 30px;
  }
}
.top-bar {
  position: relative;
  background-color:$main;
  height: 24px;
  text-align: center;
  font-size: 12px;
  &__wrapper {
    margin: 0 auto;
  }
  &__icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  * {
    vertical-align: sub;
    color: $white;
    // text-transform: lowercase;
  }
}
@media (hover: hover) {
  .icon {
    opacity: 0.6;
    &:hover,
    &:focus {
      // background-color: $color-icon-hover;
      color: $main;
      opacity: 1;
    }
  }
}
.right-icons {
  //for edge
  float: right;

}
.header-placeholder {
  height: 71px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    height: 0;
  }
}
.header-container {
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.links {
  text-decoration: underline;
}
.flex-basis-100 {
  flex-basis: 100%;
}
@media (min-width: 768px) and (max-width: 1095px) {
  .col-special {
    max-width: 80%;
    flex-basis: 80%;
  }
  .logo-block {
    flex-basis: initial;
    max-width: initial;
    a {
      margin-left: 0;
    }
  }
}
</style>
