<template>
  <footer id="main-footer" :class="{ 'brdr-top-1 brdr-cl-secondary': isCheckoutPage }">
    <!-- <newsletter class="flex brdr-bottom-1 brdr-cl-secondary" :class="{'footer-margin': !hideButtonLogic}" v-if="!isCheckoutPage && !isMyAccount" /> -->
    <div
      class="footer-links flex pt20 pb20 px40 bg-cl-main-smoke"
      v-show="showButtonLogic()"
    >
      <div class="container">
        <div class="row m0">
          <div class="col-xs-12 middle-xs center-xs flex pointer" @click="toogleFooter = true">
            {{ $t('Hide footer') }}
            <i class="icon-rozwi up open-footer-icon pl5 h4" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="footer-links save-margin flex pt30 pb45 px40 bg-cl-main-smoke"
      v-show="showLogic()"
    >
      <div class="container p0">
        <div class="row m0 top-xs">
          <div
            class="row m0 col-md-3 col-xs-12 start-md center-xs p0"
          >
            <img
              class="footer-logo"
              alt="Kultowy.pl Logo"
              src="/assets/kultowy-logo.svg"
              height="40"
              width="160"
              data-testid="footer-logo"
            >
          </div>
          <div class="links-container row m0 col-md-9 col-xs-12 p0">
            <div class="links-box row m0 col-md-3 col-xs-12 p0">
              <p class="weight-900 mt0">
                {{ $t('About us') }}
              </p>
              <div class="flex w-100" v-for="(link, i) in aboutUs" :key="i">
                <router-link class="cl-black h5 link" :to="localizedRoute(link.link)" exact>
                  {{ link.title }}
                </router-link>
              </div>
            </div>
            <div class="links-box row m0 col-md-4 col-xs-12 custom-paddings">
              <p class="weight-900 mt0">
                {{ $t('Customer service') }}
              </p>
              <div class="flex w-100" v-for="(link, indexClient) in client" :key="indexClient">
                <router-link class="cl-black h5 link" :to="localizedRoute(link.link)" exact>
                  {{ link.title }}
                </router-link>
              </div>
            </div>
            <div class="row m0 col-md-5 col-xs-12 custom-paddings">
              <p class="weight-900 mt0">
                {{ $t('Payment methods') }}
              </p>
              <div class="flex wrap">
                <div class="pr15 pb10" v-for="(payment, indexPayment) in payments" :key="indexPayment">
                  <img v-lazy="payment.image" :alt="payment.alt" width="60" height="20" :class="payment.alt === 'MasterCard Logo' ? 'payment-master' : 'payment-logo'">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="anchor" class="row m0 center-xs col-xs-12 env-padding-bottom">
          <p class="footer-date m0 h5">
            {{ new Date().getFullYear() }} Burda Media Polska
          </p>
        </div>
      </div>
    </div>
    <div class="env-padding-bottom" v-show="hideButtonLogic()">
      <div
        class="footer-links save-margin flex pt20 pb20 px40 bg-cl-main-smoke"
      >
        <div class="container">
          <div class="row m0">
            <div class="col-xs-12 middle-xs center-xs flex pointer" @click="toogleFooter = false, scrollTo()">
              {{ $t('Show footer') }}
              <i class="icon-rozwi open-footer-icon pl5 h4" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top class="env-padding-bottom" bottom="55px" right="20px" visibleoffset="200">
      <button aria-label="Back to top" type="button" class="btn-top button no-outline brdr-none cl-white bg-cl-mine-shaft :bg-cl-th-secondary py10 px10 brdr-50px flex">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" fill="white" />
        </svg>
      </button>
    </back-to-top>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { localizedRoute } from '@vue-storefront/core/lib/multistore'
import CurrentPage from 'theme/mixins/currentPage'
// import Newsletter from 'theme/components/core/blocks/Footer/Newsletter'
import i18n from '@vue-storefront/i18n'
import BackToTop from 'theme/components/core/BackToTop'
import { getPathForStaticPage } from 'theme/helpers'
import config from 'config'

export default {
  mixins: [CurrentPage],
  name: 'MainFooter',
  data () {
    return {
      toogleFooter: true,
      aboutUs: [
        { title: i18n.t('General agreement'), link: i18n.t('/i/regulamin') },
        { title: i18n.t('Privacy policy'), link: i18n.t('/i/polityka-prywatnosci') },
        { title: i18n.t('Subscription regulations'), link: i18n.t('/i/regulamin-prenumeraty') },
        { title: i18n.t('Regulamin prenumeraty - zmiany'), link: i18n.t('/i/regulamin-prenumeraty-zmiany') },
        // { title: i18n.t('FAQ'), link: i18n.t('/i/faq') },
        { title: i18n.t('Contact'), link: i18n.t('/i/kontakt') }
      ],
      client: [
        { title: i18n.t('Delivery'), link: i18n.t('/i/dostawa') },
        { title: i18n.t('Return'), link: i18n.t('/i/zwroty') },
        { title: i18n.t('Payments'), link: i18n.t('/i/platnosci') }
        // { title: i18n.t('Terms and conditions CEWE'), link: i18n.t('/i/regulamin-cewe') }
      ],
      payments: [
        { image: '/assets/payments-logo/seal.png', alt: 'Seal Logo' },
        { image: '/assets/payments-logo/visa.svg', alt: 'Visa Logo' },
        { image: '/assets/payments-logo/mastercard.svg', alt: 'MasterCard Logo' },
        { image: '/assets/payments-logo/google-pay.svg', alt: 'GooglePay Logo' },
        { image: '/assets/payments-logo/apple-pay.svg', alt: 'ApplePay Logo' },
        { image: '/assets/payments-logo/PayU.svg', alt: 'PayU Logo' },
        { image: '/assets/payments-logo/blik.svg', alt: 'Blik Logo' },
        { image: '/assets/twisto.svg', alt: 'Twisto Logo' }
        // { image: '/assets/payments-logo/paypal.svg', alt: 'PayPal Logo' }
      ]
    }
  },
  methods: {
    scrollTo () {
      let elementToView = document.getElementById('anchor')
      setTimeout(() => {
        if (elementToView) {
          window.scroll({ top: elementToView.offsetTop - 90, left: 0, behavior: 'smooth' })
        }
      }, 10)
    },
    outboundLink (text, link) {
      this.$store.commit('ui/openOutboundLink', { text, link })
    },
    goToAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    getLinkFor (path) {
      return localizedRoute(getPathForStaticPage(path))
    },
    showButtonLogic () {
      if (this.isCheckoutPage) {
        return !this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage) && !this.toogleFooter
      } else {
        return false
      }
    },
    hideButtonLogic () {
      if (this.isCheckoutPage) {
        return this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage) && this.toogleFooter
      } else {
        return false
      }
    },
    showLogic () {
      if (this.isCheckoutPage) {
        return !this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage) && !this.toogleFooter
      } else if (!(this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage)) {
        return this.toogleFooter
      } else {
        return true
      }
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLoggedIn'
    }),
    multistoreEnabled () {
      return config.storeViews.multistore
    },
    getVersionInfo () {
      return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`
    }
  },
  components: {
    // Newsletter,
    BackToTop
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-secondary: color(secondary);

.footer-margin {
  margin-bottom: env(safe-area-inset-bottom);
}
.icon {
  transition: 0.3s all;
}
i.up {
  transform: rotate(180deg);
}

.social-icon {
  width: 35px;
  height: 35px;
  &:hover,
  &:focus,
  &:active {
    .icon {
      fill: $color-secondary;
    }
  }
}

.links-container {
  margin-top: 40px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  .links-box {
    border-right: 1px solid #CFCFCF;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      border: 0;
    }
  }
  .link {
    margin-top: 5px;
    :hover {
      text-decoration: underline !important;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
      margin-top: 10px;
    }
  }
}

.payment-master {
  height: 30px;
  width: 45px;
}
.payment-logo {
  width: auto;
  height: 20px;
}

.footer-date {
  margin-top: 65px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    margin-top: 40px;
  }
}

.custom-paddings {
  padding: 0 0 0 40px;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    padding: 20px 0 0 0;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
  .footer-links {
    padding: 20px 20px 30px 20px
  }
  .save-margin {
    margin-bottom: 45px;
  }
  .social-container {
    padding: 20px 0 0 0;
  }
}
</style>
