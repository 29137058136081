import i18n from '@vue-storefront/i18n'
import config from 'config'

export default {
  title: 'Oficjalny sklep wydawnictwa Burda i Słowne',
  titleTemplate: '%s - Kultowy.pl',
  htmlAttrs: {
    lang: 'pl'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Prenumerata prasy i wydania pojedyncze magazynów. Książki, e-booki i audiobooki. E-wykroje Burda. Kolekcje kart i figurek. Sprawdź w kultowy.pl' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, minimal-ui, viewport-fit=cover' },
    { name: 'robots', vmid: 'robots', content: config.server.useNoIndexNoFollow ? 'noindex, nofollow' : 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
    { property: 'og:locale', content: 'pl_PL' },
    { vmid: 'og:site_name', property: 'og:site_name', content: i18n.t('shop') },
    { vmid: 'og:title', property: 'og:title', content: i18n.t('shop') },
    { vmid: 'og:description', property: 'og:description', content: i18n.t('shop') },
    { vmid: 'og:url', property: 'og:url', content: 'https://kultowy.pl' },
    { property: 'fb:app_id', content: '00000' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-16x16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json' },
    { rel: 'preload', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap', crossorigin: 'anonymous' },
    { rel: 'preload', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Krona+One&display=swap', crossorigin: 'anonymous' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap' },
    { rel: 'stylesheet', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Krona+One&display=swap' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com/', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: config.server.usercomUrl, crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://www.facebook.com/', crossorigin: 'anonymous' },
    { rel: 'preconnect', href: 'https://connect.facebook.net/', crossorigin: 'anonymous' }
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
      async: true,
      crossorigin: 'anonymous'
    },
    {
      src: config.server.usercomUrl + 'widget.js',
      async: true,
      defer: true,
      type: 'text/javascript'
    }
  ],
  noscript: [
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600|Krona+One&display=swap' }
  ]
}
